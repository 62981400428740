import type { Token } from "~/api/data-schema";

export function useCheckAreEditionsSoldOff(token: Token) {
    let soldOut = true;

    Object.entries(
        token?.editionsMarketData || {
            [token.tokenId]: token.marketData,
        },
    ).forEach(([, accMarketData]) => {
        soldOut = soldOut && !!accMarketData?.soldFor;
    });

    return soldOut;
}

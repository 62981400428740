import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import differenceInSeconds from "date-fns/differenceInSeconds";
import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";

export interface ITimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    totalMilliseconds: number;
}

export function calculateTimeLeft(dateStart: Date, dateEnd: Date): ITimeLeft {
    const timeLeft: ITimeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalMilliseconds: differenceInMilliseconds(dateEnd, dateStart),
    };
    if (timeLeft.totalMilliseconds > 0) {
        timeLeft.days = Math.floor(differenceInDays(dateEnd, dateStart));
        timeLeft.hours = Math.floor(differenceInHours(dateEnd, dateStart) % 24);
        timeLeft.minutes = Math.floor(
            differenceInMinutes(dateEnd, dateStart) % 60,
        );
        timeLeft.seconds = Math.floor(
            differenceInSeconds(dateEnd, dateStart) % 60,
        );
    }

    return timeLeft;
}

function formatTimeValue(value: number) {
    return value.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
}

export function buildTimeLeftString(timeLeft: ITimeLeft): string {
    return `${
        (timeLeft.days
            ? `${timeLeft.days}d ${formatTimeValue(timeLeft.hours)}h `
            : "") +
        (!timeLeft.days && timeLeft.hours
            ? `${formatTimeValue(timeLeft.hours)}h `
            : "")
    }${formatTimeValue(timeLeft.minutes)}m ${formatTimeValue(
        timeLeft.seconds,
    )}s`;
}

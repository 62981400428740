import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { EtherIcon } from "~/features/icon/EtherIcon";
import { useTokenMarketDataFromToken } from "~/features/tokenMarketData";
import { Currency, Token } from "~/api/data-schema";
import { useCurrencyExchange } from "~/features/currencyExchange";

export const useArtworkCardFooterText = ({
    token,
}: {
    token: Token;
}): {
    first: string;
    second: JSX.Element;
    third?: string;
} => {
    const {
        upcomingAuction,
        activeAuction,
        endedAuction,
        buyNowPrice,
        buyNow,
        soldFor,
        highestBidder,
        highestBid,
    } = useTokenMarketDataFromToken(token);
    const highestBidInUSD = useCurrencyExchange({
        value: highestBid,
        from: Currency.ETH,
        to: Currency.USD,
    });
    const buyNowPriceInUSD = useCurrencyExchange({
        value: buyNowPrice,
        from: Currency.ETH,
        to: Currency.USD,
    });
    const soldForInUSD = useCurrencyExchange({
        value: soldFor,
        from: Currency.ETH,
        to: Currency.USD,
    });
    const bidValue = useMemo(() => {
        return (
            <Box display="flex" alignItems="center">
                <EtherIcon />
                {`${(+highestBid)?.toFixed(2)}`}
                <Box ml="8px">{`($ ${highestBidInUSD?.toFixed(2)})`}</Box>
            </Box>
        );
    }, [highestBid, highestBidInUSD]);

    if (upcomingAuction) {
        return {
            first: "Starting bid",
            second: bidValue,
        };
    }

    if (activeAuction) {
        if (highestBidder) {
            return {
                first: "Current bid",
                second: bidValue,
                third: `by ${highestBidder}`,
            };
        }

        return {
            first: "Starting bid",
            second: bidValue,
        };
    }

    if (endedAuction) {
        if (highestBidder) {
            return {
                first: "Winning bid",
                second: bidValue,
            };
        }

        return {
            first: " ",
            second: <>No winner</>,
        };
    }

    if (buyNow) {
        return {
            first: "List price",
            second: (
                <Box display="flex" alignItems="center">
                    <EtherIcon />
                    {`${(+buyNowPrice)?.toFixed(2)}`}
                    <Box ml="8px">{`($ ${buyNowPriceInUSD?.toFixed(2)})`}</Box>
                </Box>
            ),
        };
    }

    if (soldFor) {
        return {
            first: "Last sale",
            second: (
                <Box display="flex" alignItems="center">
                    <EtherIcon />
                    {`${(+soldFor)?.toFixed(2)}`}
                    <Box ml="8px">{`($ ${soldForInUSD?.toFixed(2)})`}</Box>
                </Box>
            ),
        };
    }

    return {
        first: " ",
        second: <></>,
    };
};

import { Box, BoxProps } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import { styled } from "@mui/system";
import { colors } from "~/theme";

const SkeletonCardBox = styled(Box)({
    margin: "36px 21px",
    transform: "none",
    border: `1px solid ${colors.cardGrey}`,
});
const CountdownSkeleton = styled(Skeleton)({
    margin: "7px 21px",
});
const ImageSkeleton = styled(Skeleton)({
    transform: "none",
});
const TitleContainer = styled(Box)({
    paddingLeft: 12,
    paddingRight: 12,
    height: 70,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
});
const TitleSkeleton = styled(Skeleton)({
    width: "70%",
});
const ArtistSkeleton = styled(Skeleton)({
    width: "35%",
});
const BidsContainer = styled(Box)({
    paddingLeft: 12,
    paddingRight: 12,
    height: 60,
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${colors.cardGrey}`,
});
const BidsSkeleton = styled(Skeleton)({
    width: "45%",
});

export const SkeletonCard: React.FC<
    BoxProps & {
        animation?: false | "wave" | "pulse" | undefined;
        width?: string | number;
        height?: string | number;
        size?: string | number;
    }
> = ({ className, animation, width, height, size, ...rest }) => {
    return (
        <SkeletonCardBox
            className={className}
            height={height || 519}
            width={width || 352}
            data-testid="skeleton-card"
            {...rest}
        >
            <Box>
                <CountdownSkeleton animation={animation} />
            </Box>

            <Box>
                <ImageSkeleton animation={animation} height={size || 354} />
            </Box>
            <Box>
                <TitleContainer>
                    <TitleSkeleton animation={animation} />
                    <ArtistSkeleton animation={animation} />
                </TitleContainer>
                <BidsContainer>
                    <BidsSkeleton animation={animation} />
                </BidsContainer>
            </Box>
        </SkeletonCardBox>
    );
};

import React, { useMemo } from "react";
import { Box, Radio } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import noop from "lodash/noop";
import { styled } from "@mui/material/styles";
import { CountdownTimer } from "~/features/countdown";
import { fonts } from "~/theme";
import { useTokenMarketDataFromToken } from "~/features/tokenMarketData";
import { Token } from "~/api/data-schema";

const MOBILE_MAX = 767;

const StyledAuctionInfo = styled(Box)(({ theme }) => ({
    ...fonts.px20400,
    [theme.breakpoints.down(MOBILE_MAX)]: {
        ...fonts.px16400,
    },
}));

export const ArtworkCardHeader = ({
    token,
    onSelect,
    selected,
}: {
    token: Token;
    onSelect?: Function;
    selected?: boolean;
}) => {
    const {
        upcomingAuction,
        activeAuction,
        endedAuction,
        buyNow,
        soldFor,
        auction,
    } = useTokenMarketDataFromToken(token);

    const headerText = useMemo(() => {
        if (upcomingAuction) {
            return "Starting in: ";
        }
        if (activeAuction) {
            return "Closing in: ";
        }
        if (endedAuction) {
            return "Auction ended";
        }
        if (buyNow) {
            return "Buy Now";
        }
        if (soldFor) {
            return "Sold";
        }

        return "";
    }, [upcomingAuction, activeAuction, endedAuction, buyNow, soldFor]);

    return (
        <Box height="24px" mb="16px" display="flex" alignItems="center">
            <StyledAuctionInfo>
                {headerText}
                {auction && upcomingAuction && (
                    <CountdownTimer
                        endTime={new Date(auction.auctionStartDate)}
                    />
                )}
                {auction && activeAuction && (
                    <CountdownTimer
                        endTime={new Date(auction.auctionEndDate)}
                    />
                )}
            </StyledAuctionInfo>

            {onSelect && (
                <Box
                    justifySelf="flex-end"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        e.preventDefault();
                    }}
                    onChange={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        e.preventDefault();
                    }}
                >
                    <FormControlLabel
                        control={
                            <Radio
                                checked={selected}
                                color="primary"
                                size="small"
                                onChange={
                                    (onSelect ||
                                        noop) as React.ChangeEventHandler
                                }
                            />
                        }
                        label=""
                    />
                </Box>
            )}
        </Box>
    );
};

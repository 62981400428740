import Box from "@mui/material/Box";
import React from "react";
import { Typography } from "@mui/material";
import { colors, getFontFamilyBySupportedApplication } from "~/theme";
import { CountdownTimer } from "~/features/countdown/CountdownTimer";

export function BidDialogCountdownTimer({
    auctionEndTime,
    onAuctionEnd,
}: {
    auctionEndTime: Date;
    onAuctionEnd: () => void;
}) {
    return (
        <Typography>
            <Box
                component="span"
                display="flex"
                color={colors.auctionTimerRed}
                fontSize="18px"
                fontWeight="600"
                fontFamily={getFontFamilyBySupportedApplication()}
            >
                Closes in:&nbsp;
                <CountdownTimer
                    endTime={auctionEndTime as Date}
                    onCountEnd={onAuctionEnd}
                />
            </Box>
        </Typography>
    );
}

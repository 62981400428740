import React, { useMemo, CSSProperties } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import truncate from "lodash/truncate";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { colors, fonts } from "~/theme";
import { ArtworkMedia } from "~/features/artwork/media";
import { Token } from "~/api/data-schema";
import { useTokenMarketDataFromToken } from "~/features/tokenMarketData/useTokenMarketData";
import { useCheckAreEditionsSoldOff } from "~/features/tokenMarketData/useCheckAreEditionsSoldOff";
import { generatePDPLink } from "~/features/PDP/generatePDPLink";
import { useV2TokenContractAddress } from "~/features/solidityContracts/useV2TokenContractAddress";
import { useScreenOptions } from "~/features/screen";
import { useArtworkCardFooterText } from "~/features/artwork/card/useArtworkCardFooterText";
import { ArtworkCardHeader } from "~/features/artwork/card/ArtworkCardHeader";
import { useEditionNumber } from "~/features/artwork/useEditionNumber";

const MOBILE_MAX = 767;
const CARD_WIDTH = 400;
const CARD_HEIGHT = 648;
const CARD_WIDTH_MOB = 343;

export const StyledCardWrapper = styled(Box)(({ theme }) => ({
    width: `${CARD_WIDTH}px`,
    padding: 0,
    marginLeft: "4px",
    marginRight: "4px",
    marginBottom: "48px",
    [theme.breakpoints.down(MOBILE_MAX)]: {
        maxWidth: `${CARD_WIDTH_MOB}px`,
        width: "auto",
        marginBottom: "16px",
    },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
    "&.MuiCard-root": {
        padding: "17px",
        boxShadow: "none",
        borderRadius: 0,
        border: `1px solid ${colors.black20}`,
        color: theme.palette.secondary.main,
        height: "100%",
    },
}));

export const StyledCardContent = styled(CardContent)(() => ({
    "&.MuiCardContent-root": {
        padding: 0,
        color: colors.black,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
}));

export const StyledArtistName = styled(Box)(() => ({
    ...fonts.hx13400120,
}));

export const StyledTitle = styled(Box)(({ theme }) => ({
    ...fonts.hx26500,
    [theme.breakpoints.down(MOBILE_MAX)]: {
        ...fonts.px20400,
        fontWeight: 500,
    },
    marginTop: "8px",
    marginBottom: "16px",
}));

export const StyledTokenMarketBlock = styled(Box)(() => ({
    borderTop: `1px solid ${colors.black20}`,
    paddingTop: "16px",
    paddingBottom: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
}));

export const StyledBidTitle = styled(Box)(() => ({
    ...fonts.px16400,
    color: colors.black40,
}));

export const StyledBid = styled(Box)(({ theme }) => ({
    ...fonts.hx26500,
    [theme.breakpoints.down(MOBILE_MAX)]: {
        ...fonts.px20400,
        fontWeight: 500,
    },
    marginTop: "8px",
}));

export const StyledBidder = styled(Box)(() => ({
    ...fonts.px16400,
    fontWeight: 300,
    marginTop: "8px",
}));

export const StyledEditionOf = styled(Box)(({ theme }) => ({
    ...fonts.px20400,
    [theme.breakpoints.down(MOBILE_MAX)]: {
        ...fonts.hx13400120,
    },
    fontWeight: 300,
}));

export const ArtworkCard: React.FC<{
    parentToken: Token;
    blockchainId: number;
    optimized?: boolean;
    href?: string;
    baseUri?: string;
    selected?: boolean;
    onSelect?: Function;
    quality?: "low" | "medium" | "high";
    cardStyle?: CSSProperties;
    alignHeight?: boolean;
}> = ({
    parentToken,
    optimized,
    blockchainId,
    href,
    selected,
    onSelect,
    baseUri,
    quality = "medium",
    cardStyle,
    alignHeight = false,
}) => {
    const { name, artistName } = parentToken.metadata.properties || {};
    const { tokenSupply } = parentToken || {};
    const { isMobile } = useScreenOptions();

    const { auction, tokenWithMarketDataId } =
        useTokenMarketDataFromToken(parentToken);
    const editionNumber =
        useEditionNumber(parentToken, +tokenWithMarketDataId) || 1;

    const soldOut = useCheckAreEditionsSoldOff(parentToken);

    const footerText = useArtworkCardFooterText({ token: parentToken });

    const v2ContractAddress = useV2TokenContractAddress(
        parentToken,
        blockchainId,
    );
    const tokenContractAddress = useMemo(() => {
        if (parentToken?.tokenContractAddress) {
            return parentToken.tokenContractAddress;
        }

        return v2ContractAddress;
    }, [parentToken?.tokenContractAddress, v2ContractAddress]);

    if (!parentToken?.metadata?.properties) {
        return null;
    }

    return (
        <a
            href={
                href ||
                generatePDPLink({
                    tokenId: Number(tokenWithMarketDataId),
                    application: parentToken.metadata.properties.application
                        ?.description as SupportedApplication,
                    blockchainId,
                    tokenContractAddress: tokenContractAddress as string,
                    baseUrl: baseUri,
                    withBasePath: true,
                })
            }
            data-testid="artwork-card"
        >
            <StyledCardWrapper
                style={{ height: alignHeight ? CARD_HEIGHT : "auto" }}
            >
                <StyledCard
                    data-token-id={tokenWithMarketDataId}
                    data-target="artwork-card"
                    style={{ border: cardStyle?.border }}
                >
                    <StyledCardContent>
                        {auction && (
                            <ArtworkCardHeader
                                token={parentToken}
                                onSelect={onSelect}
                                selected={selected}
                            />
                        )}
                        <Box
                            height={isMobile ? 307 : 424}
                            maxWidth={isMobile ? 307 : 424}
                            mb="16px"
                            sx={{
                                "& .slider-wrapper": {
                                    height: `${
                                        (isMobile ? 307 : 424) - 40
                                    }px !important`,
                                },
                            }}
                        >
                            <ArtworkMedia
                                metadata={parentToken.metadata}
                                width={isMobile ? 307 : 424}
                                optimized={optimized}
                                quality={quality}
                            />
                        </Box>
                        <Box>
                            <StyledArtistName>
                                {artistName?.description}
                            </StyledArtistName>
                            <StyledTitle
                                data-artwork-name={name.description}
                                id={`title-${name.description}`}
                                data-testid={`title-${name.description}`}
                            >
                                {truncate(name.description, {
                                    length: 35,
                                })}
                            </StyledTitle>
                            <StyledTokenMarketBlock>
                                <Box>
                                    <StyledBidTitle data-testid="footer-text-1">
                                        {footerText.first}
                                    </StyledBidTitle>
                                    <StyledBid data-testid="footer-text-2">
                                        {footerText.second}
                                    </StyledBid>
                                    {footerText.third && (
                                        <StyledBidder data-testid="footer-text-3">
                                            {footerText.third}
                                        </StyledBidder>
                                    )}
                                </Box>
                                {soldOut && (
                                    <StyledEditionOf>Sold out</StyledEditionOf>
                                )}
                                {!!tokenSupply && !soldOut && (
                                    <StyledEditionOf>
                                        {`${
                                            isMobile ? `` : `Edition `
                                        }${editionNumber}/${tokenSupply}`}
                                    </StyledEditionOf>
                                )}
                            </StyledTokenMarketBlock>
                        </Box>
                    </StyledCardContent>
                </StyledCard>
            </StyledCardWrapper>
        </a>
    );
};

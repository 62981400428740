import isFunction from "lodash/isFunction";
import { useEffect, useState } from "react";
import { calculateTimeLeft, ITimeLeft } from "./countdownProcessing";

interface Props {
    startTime?: Date;
    endTime: Date;
    onTick?: (timeLeft: ITimeLeft) => void;
    onCountEnd?: () => void;
    pause?: boolean;
}

export function useCountdown({
    startTime = new Date(),
    endTime,
    onCountEnd,
    onTick,
    pause,
}: Props): ITimeLeft {
    const [timeLeft, setTimeLeft] = useState<ITimeLeft>(
        calculateTimeLeft(startTime, endTime),
    );

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (pause) return;

            const now = new Date();
            const timeRemaining = calculateTimeLeft(now, endTime);
            setTimeLeft(timeRemaining);

            if (timeLeft.totalMilliseconds > 0) {
                if (isFunction(onTick)) {
                    onTick(timeLeft);
                }
            } else {
                clearInterval(intervalId);

                if (isFunction(onCountEnd)) {
                    onCountEnd();
                }
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [endTime, onCountEnd, onTick, pause, timeLeft]);

    return timeLeft;
}

import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { colors, getFontFamilyBySupportedApplication } from "~/theme";
import CalendarIconLG from "./calendarIconLG.svg";
import { CountdownTimer } from "./CountdownTimer";
import { BasicAuction } from "~/api/data-schema";

const AuctionStarts = styled(Box)(() => ({
    fontSize: 22,
    fontFamily: getFontFamilyBySupportedApplication(),
    lineHeight: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: 0,
}));

export type Props = {
    auction: BasicAuction;
};

export const AuctionStartCountdownTimer: React.FC<Props> = ({ auction }) => {
    const theme = useTheme();
    const [showtimeToAuction, setShowTimeToAuction] = useState(false);
    const onCountEnd = useCallback(() => {
        setShowTimeToAuction(false);
    }, []);

    useEffect(() => {
        setShowTimeToAuction(auction.auctionStartDate > new Date());
    }, [auction.auctionStartDate]);

    if (!showtimeToAuction) {
        return null;
    }

    return (
        <AuctionStarts
            borderBottom={`1px solid ${colors.borderGrey}`}
            padding="16px 0"
            color={theme.palette.secondary.main}
            data-target="auction-countdown-timer"
        >
            <CalendarIconLG
                color={theme.palette.secondary.main}
                height="35px"
            />
            &nbsp;Auction Starts:&nbsp;
            <CountdownTimer
                endTime={auction.auctionStartDate}
                onCountEnd={onCountEnd}
            />
        </AuctionStarts>
    );
};

import { Token } from "~/api/data-schema";

export function useEditionNumber(
    parentToken: { editions: Token[] },
    tokenId: number,
) {
    return (
        parentToken.editions.findIndex(
            (el) => el.tokenId.toString() === tokenId.toString(),
        ) + 1
    );
}

import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Token } from "~/api/data-schema";
import { solidityContractStateSelector } from "~/features/solidityContracts/solidityContractsReducer";
import { loadDeployedContractsList } from "~/api/choreography/solidityContracts";

export function useV2TokenContractAddress(
    token: Token | undefined,
    blockchainId: number,
) {
    const application = token?.metadata?.properties?.application
        ?.description as SupportedApplication;
    const { deployedContracts = [] } = useSelector(
        solidityContractStateSelector,
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!deployedContracts.length) {
            dispatch(loadDeployedContractsList());
        }
    }, [deployedContracts.length, dispatch]);

    return useMemo(() => {
        return deployedContracts.find((el) => {
            return (
                el.blockchainId === blockchainId &&
                el.application === application
            );
        })?.abi;
    }, [application, blockchainId, deployedContracts]);
}

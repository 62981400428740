import React from "react";
import { isStorybook } from "~/features/env";
import { buildTimeLeftString, ITimeLeft } from "./countdownProcessing";
import { useCountdown } from "./useCountdown";

interface Props {
    startTime?: Date;
    endTime: Date;
    onTick?: (timeLeft: ITimeLeft) => void;
    onCountEnd?: () => void;
    pause?: boolean;
}

export const CountdownTimer: React.FC<Props> = ({
    startTime = new Date(),
    endTime,
    onCountEnd,
    onTick,
    pause = isStorybook(),
}) => {
    const timeLeft = useCountdown({
        startTime,
        endTime,
        onCountEnd,
        onTick,
        pause,
    });

    return (
        <span
            dangerouslySetInnerHTML={{ __html: buildTimeLeftString(timeLeft) }}
        />
    );
};
